<template>
    <v-dialog
        v-model="selectorDialog"
        hide-overlay
        max-width="250px"
        persistent
        width="250px"
    >
        <v-form ref="productForm" lazy-validation>
            <v-card>
                <v-card-title class="px-4">{{ $t('message.changeProduct') }}</v-card-title>
                <v-card-text class="px-4">
                    <v-select
                        :items="options.products"
                        :loading="loading.options"
                        :placeholder="$t('message.typeToSearch')"
                        :rules="[...validationRules.required]"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="ProductProperty.title"
                        item-value="ProductProperty.id"
                        ref="productComboBox"
                        return-object
                        solo
                        v-model="selectedProduct"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2" @click="closeSelector" small>{{ $t('message.cancel') }}</v-btn>
                    <v-btn @click="updateProduct" :loading="loading.update" small color="info">{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>

import {api} from "Api";
import {validationRules} from "@/store/modules/appic/constants";
import {mapFields} from "vuex-map-fields";

export default {
    name: 'ChangeProduct',
    props: ['currentProduct','productId','openDialog','supplierId','customerId'],
    data() {
        return {
            dialog: false,
            loading: {
                update: false,
                options: false
            },
            selectedProduct: null
        }
    },
    computed: {
        ...mapFields('supplier',{
            options: 'options'
        }),
        validationRules() {
            return validationRules
        },
        selectorDialog: {
            get() {
                return this.dialog;
            },
            set(value){
                this.dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        updateProduct() {
            this.$refs.productComboBox.blur()
            this.$nextTick(() => {
                if(this.$refs.productForm.validate()) {
                    this.loading.add = true
                    const product = {
                        productgroup_id: this.selectedProduct.ProductProperty.id
                    }
                    api
                        .put('/products/' + this.productId, {
                            product: product
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.$toast.success(this.$t('message.successes.productUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.$emit('product-updated')
                            } else {
                                this.$toast.error(this.$t('message.errors.productNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.update = false
                            this.closeSelector()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.productNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.update = false
                        })
                }
            })
        },
        closeSelector(){
            this.$refs.productForm.reset()
            this.selectorDialog = false
            this.selectedProduct = null
        },
        loadList() {
            return new Promise((resolve, reject) => {
                this.loading.options = 'warning'
                const property = 'Product.product'
                api
                    .get('/products/properties/' + property + '/options', {
                        params: {
                            conditions: [
                                {
                                    field: 'supplier_id',
                                    value: parseInt(this.supplierId)
                                }
                            ]
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success'){
                            this.options.products = response.data.data
                        } else {
                            this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.options = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.options = false
                    })
            })
        }
    },
    watch: {
        openDialog(value) {
            this.$nextTick(function(){
                if(value == true) {
                    this.options.product = []
                    this.loadList()
                }
                this.selectedProduct = null
                this.dialog = value
            })
        }
    }
}

</script>

<style scoped>

</style>